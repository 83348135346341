export const NAME_SORT_LABEL: Record<"false" | "true", string> = {
  false: "Name A-Z",
  true: "Name Z-A",
};

export enum TABLE_ENUM {
  TIMESHEETS = "timesheets",
  TALK_2_GROW = "talk2grow",
  EMPLOYEES = "employees",
  ARCHIVED_EMPLOYEES = "archivedEmployees",
  EMPLOYEE_ASSIGNMENTS = "employeeAssignments",
  EMPLOYEE_CONTRACTS = "employeeContracts",
  EMPLOYEE_DOCUMENTS = "employeeDocuments",
  ACCOUNTING = "accounting",
  PROJECTS = "projects",
  ASSIGNMENTS = "assignments",
  TICKETS = "tickets",
  DOCUMENTS = "documents",
  EVENTS = "events",
  IMPORT_CONFLICTS = "importConflicts",
  IMPORT_ERRORS = "importErrors",
  PROJECT_EMPLOYEES = "projectEmployees",
  PROJECT_REPORT = "projectReport",
  MY_REQUESTS = "myRequests",
}
