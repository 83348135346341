export const LOCAL_STORAGE = {
  authToken: "AUTH_TOKEN",
  userPayload: "USER_PAYLOAD",
  hasuraToken: "HASURA_TOKEN",
  userId: "USER_ID",
  externalId: "EXTERNAL_ID",
  hasuraRole: "HASURA_ROLE",
};

export const COOKIES = {
  erpMainToken: "erp_token",
  hasuraToken: "hasura_access_token",
  managerId: "manager_id",
};

export const AUTH_TOKEN_NAME = "ACAI_EMPLOYEE_PORTAL_AUTH_TOKEN";
export const FILTERS_NAME = "ACAI_EMPLOYEE_PORTAL_FILTERS";
export const FILTERS_OPENED = "ACAI_EMPLOYEE_PORTAL_FILTERS_OPENED";
export const AUTH0_CALIMS_NAME = "https://hasura.io/jwt/claims";

export enum USER_ROLES_ENUM {
  EMPLOYEE = "employee",
  MANAGER = "manager",
}

export enum EMPLOYEE_STATUS_ENUM {
  ACTIVE = "ACTIVE",
  MATERNITY_LEAVE = "MATERNITY_LEAVE",
  STARTING = "STARTING",
  TERMINATION = "TERMINATION",
  LONG_HOLIDAYS = "LONG_HOLIDAYS",
  ARCHIVED = "ARCHIVED",
}

export const EMPLOYEE_STATUS_LABELS_MAPPING = {
  [EMPLOYEE_STATUS_ENUM.ACTIVE]: "Active",
  [EMPLOYEE_STATUS_ENUM.TERMINATION]: "Termination",
  [EMPLOYEE_STATUS_ENUM.STARTING]: "Starting",
  [EMPLOYEE_STATUS_ENUM.ARCHIVED]: "Archived",
  [EMPLOYEE_STATUS_ENUM.LONG_HOLIDAYS]: "Long holidays",
  [EMPLOYEE_STATUS_ENUM.MATERNITY_LEAVE]: "Maternity leave",
};


export enum EMPLOYMENT_REASON_FOR_TERMINATION_ENUM {
  CLIENT_ISSUES = "CLIENT_ISSUES",
  MONEY = "MONEY",
  UNDER_PERFORMANCE = "UNDER_PERFORMANCE",
}

export enum USER_CATEGORY_ROLES_ENUM {
  GENERAL = "general",
  DEVELOPER = "developer",
  QA = "qa",
  DEVOPS = "devops",
  UIUX = "uiux",
}

const GOOGLE_CALENDAR = {
  BASE_URL: "https://www.googleapis.com/calendar/v3/calendars",
  ID: "holiday@group.v.calendar.google.com",
  REGION: "en.polish",
};

export const GOOGLE_CALENDAR_URL = `${GOOGLE_CALENDAR.BASE_URL}/${GOOGLE_CALENDAR.REGION}%23${GOOGLE_CALENDAR.ID}/events?key=${process.env.REACT_APP_GOOGLE_CALENDAR_API_KEY}`;

export enum TICKET_STATUS_ENUM {
  READY = "READY",
  ARCHIVED = "ARCHIVED",
  OPEN = "OPEN",
}

export const TICKET_STATUS_LABELS_MAPPING = {
  [TICKET_STATUS_ENUM.READY]: "Ready",
  [TICKET_STATUS_ENUM.ARCHIVED]: "Archived",
  [TICKET_STATUS_ENUM.OPEN]: "Open",
};

export enum DOCUMENT_TYPE_ENUM {
  DOCUMENT = "document",
  SPREADSHEET = "spreadsheet",
  PRESENTATION = "presentation",
  FORM = "form",
}

export const DOCUMENT_TYPE_ENUM_MAPPING = {
  [DOCUMENT_TYPE_ENUM.DOCUMENT]: "document",
  [DOCUMENT_TYPE_ENUM.SPREADSHEET]: "spreadsheet",
  [DOCUMENT_TYPE_ENUM.PRESENTATION]: "presentation",
  [DOCUMENT_TYPE_ENUM.FORM]: "form",
}
