import axios, { AxiosError } from "axios";
import API from "config/api";
import { useAuthContext } from "contexts";
import { useCallback } from "react";
import { toast } from "react-toastify";
import useSWRMutation, { MutationFetcher } from "swr/mutation";
import { EmployeeDocumentForm, EmployeeDocument } from "types/employee";

export type CreateEmployeeDocumentsRequestParams = {
  employeeId: string;
};

export type CreateEmployeeDocumentsRequestArgs = {
  requestQueryParams: CreateEmployeeDocumentsRequestParams;
  requestBody?: EmployeeDocumentForm;
};

export type EmployeeDocumentResponse = {
  link: string;
};

export const useCreateEmployeeDocument = () => {
  const { token } = useAuthContext();
  const createEmployeeDocuments = useCallback<
    MutationFetcher<EmployeeDocumentResponse, string, CreateEmployeeDocumentsRequestArgs>
  >(
    async (
      url,
      {
        arg: {
          requestBody,
          requestQueryParams: { employeeId },
        }
      }
    ) => {
      try {
        const { data } = await axios.post<EmployeeDocumentResponse>(
          `${url}/${employeeId}`,
          requestBody || {},
          {
            headers: {
              "Authorization": token ? `Bearer ${token}` : "",
              "Content-Type": "application/json",
            },
            transformResponse: (response) => {
              const data: EmployeeDocument =
                JSON.parse(response || "");
              return data;
            },
          }
        );
        return data;
      } catch (e) {
        toast.error("Something went wrong.");
        throw new Error((e as AxiosError)?.message);
      }
    },
    [token]
  );

  return useSWRMutation(
    `${process.env.REACT_APP_BACKEND_URL}${API.employeeDocuments}`,
    createEmployeeDocuments,
    { throwOnError: false }
  );
};
